<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>InterChange Results</span>
              </label>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :header-props="{ sortIcon: null }"
              class="color-green disabled-footer"
              height="calc(100vh - 369px)"
              :headers="headers"
              :items="desserts"
              fixed-header
            >
              <template v-slot:item.image="{ item }">
                <div class="p-2">
                  <v-img width="30" :src="item.image" :alt="item.name"></v-img>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-responsive>
      </v-col> </v-row
  ></v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Part Number",
          align: "start",
          sortable: false,
          value: "partn",
        },
        { text: "Damage Code", value: "damagecode" },
        { text: "Sale $", value: "sale" },
        { text: "Yard Location", value: "location" },
        { text: "Stoke #", value: "stock" },
        { text: "Warranty", value: "wr" },
        { text: "Dismsntler Name", value: "dsname" },
        { text: "Address", value: "add" },

        { text: "Tel", value: "tel" },
        { text: "Rep", value: "rep" },
        { text: "Part Image", value: "image" },
      ],
      desserts: [
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          partn: "152012",
          damagecode: "A",
          sale: "$250.20",
          location: "Real Shelf",
          stock: "54804545",
          wr: "5 days",
          dsname: "Phonenix",
          add: "London",
          tel: "1234567890",
          rep: "Peter",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
      ],
    };
  },
};
</script>
